@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less"; // Import Ant Design styles by less entry
@import "theme.less"; // Import Ant Design styles by less entry
@import "variable.less"; // Map theme to accessible root variables

.App {
  text-align: center;
  min-height: 600px;
  background-color: @primary-color;
}

.App-header {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: #ffffff;
  color: white;
}

.sideBarToggle {
  padding: 0;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.sideBarToggle:hover {
  color: #1890ff;
}

.ant-form-item-label {
  text-align: left;
}

.ant-page-header {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.stepForm {
  text-align: left;
  padding: 0px;
}

.stepForm .ant-steps {
  padding-bottom: 24px;
}

.stepForm .ant-form-item-label {
  font-weight: 500;
}

.stepForm .ant-form-item {
  margin: 40px 0px;
}

.ant-divider-horizontal {
  margin: 40px 0px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  content: "*";
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-inline .ant-form-item {
  margin-bottom: 30px !important;
}

.ant-layout-footer {
  padding: 0px;
  color: white;
  font-size: 14px;
  background: #f0f2f5;
}

.ant-card-body {
  padding: 16px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 10px !important;
}

.ant-form-vertical .ant-form-item-label > label {
  height: 24px;
}

tr.ant-table-expanded-row > td {
  &:hover {
    background-color: white;
  }
  background-color: white;
}

.ant-row.ant-form-item label{
  font-weight: 600;
}

.ant-picker-range-arrow {
  display: none !important;
}

.ant-input-search-button {
  border: none;
}

.ant-table-row {
  .unexpanded-svg {
    display: none;
  }

  .expanded-svg {
    display: block;
  }
}
.ant-table-row:hover {
  .unexpanded-svg {
    display: block;
  }
}

